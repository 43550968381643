<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" style="min-height: 78vh;">
      <div class="col-12" v-if="dataReady === true && inserting === false">
        <div class="card h-100">
          <div class="card-body">
            <div class="row justify-content-end">
              <div class="col-md-6 text-end">
                <div class="mb-3">
                  <a href="javascript:void(0);" class="btn btn-primary"   @click="insertUser()"
                    ><i class="mdi mdi-plus me-2"></i> Aggiungi cliente</a
                  >
                  <!-- <b-button @click.prevent="randomUser()" class="ms-1" variant="primary" 
                      >Casuale</b-button
                    > -->
                </div>
              </div>
            </div> 
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Visualizza  &nbsp; 
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;risultati
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter  "
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Cerca..."
                      class="form-control rounded bg-light border-0 ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                class="table table-centered table-nowrap"
                :items="userList"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
               
                <template v-slot:cell(name)="data">
                  <div                   
                    class="avatar-xs d-inline-block me-2"
                  >
                    <div
                      class="avatar-title rounded-circle"
                      :style="`background-color:${data.item.color};`"
                    >
                    <i class="uil uil-user font-size-18"></i>
                      <!--<i class="mdi mdi-account-circle m-0"></i>-->
                    </div>
                  </div>
                  <span class="text-body">{{ data.item.name }} {{ data.item.lastname }}</span>
                </template>
                <template v-slot:cell(azioni)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a
                        href="javascript:void(0);"
                        class="px-2"
                        v-b-tooltip.hover
                        title="Anteprima"
                        style="color:#0071DC"
                        @click="selectUser(data.item)"
                      >
                        <i class="uil uil-eye font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <router-link :class="{'px-2':true}" :to="{ name: 'patient', params: { id: data.item.id, tab: 'main' }}">
                        <i style="color:#fb913b" class="uil uil-pen font-size-18"></i>
                      </router-link>
                    </li>
                    <b-dropdown
                      class="list-inline-item"
                      variant="white"
                      right
                      toggle-class="text-muted font-size-18 px-2"
                    >
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-v"></i>
                      </template>

                      <!-- <a class="dropdown-item"  @click="selectSelling(data.item)" >Registra vendita</a> -->
                      <router-link :class="{'dropdown-item':true}" :to="{ name: 'patient', params: { id: data.item.id, tab: 'clinics' }}">
                        Scheda cliente
                      </router-link>

                      <router-link :class="{'dropdown-item':true}" :to="{ name: 'patient', params: { id: data.item.id, tab: 'exercies' }}">
                        Risultati
                      </router-link>

                      <a class="dropdown-item"  @click="confirmDelete(data.item.id)">
                        Elimina
                      </a>
                    </b-dropdown>
                  </ul>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <b-modal
              v-model="eventModal"
              title="Edit Event"
              title-class="text-black font-18"
              size="lg"
              hide-footer
              body-class="p-3"
            >
              <div slot="modal-title"><i class="uil uil-user font-size-18"></i> Anagrafica cliente</div>
                <form @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-xl-6 col-12">
                      <div class="mb-3">
                        <label for="name" class="form-label">Nome</label>
                        <input
                          id="name"
                          v-model="currentUser.name"
                          type="text"
                          value=""
                          :disabled="edit === true"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && this.$v.currentUser.name.$error }"
                          placeholder="Inserisci Nome"
                        />
                        <div v-if="submitted && this.$v.currentUser.name.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentUser.name.required">Campo obbligatorio</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-12">
                      <div class="mb-3">
                        <label class="control-label form-label">Cognome</label>
                        <input
                            id="lastname"
                            v-model="currentUser.lastname"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': submitted && this.$v.currentUser.lastname.$error }"
                            :disabled="edit === true"
                            placeholder="Inserisci cognome"
                          />
                          <div v-if="this.submitted && this.$v.currentUser.lastname.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentUser.lastname.required">Campo obbligatorio</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-3">
                        <label class="control-label form-label">Codice Fiscale</label>
                        <input
                            id="codCf"
                            v-model="currentUser.codCF"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': submitted && this.$v.currentUser.codCF.$error }"
                            :disabled="edit === true"
                            placeholder="Inserisci Codice Fiscale"
                        />
                        <div v-if="submitted && this.$v.currentUser.codCF.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentUser.codCF.required">Campo obbligatorio</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-10">
                      <div class="mb-3">
                        <label class="control-label form-label">Indirizzo E-mail</label>
                        <input
                            id="email"
                            v-model="currentUser.email"
                            type="text"
                            class="form-control"
                            :disabled="edit === true"
                            :class="{ 'is-invalid': submitted && this.$v.currentUser.email.$error }"
                            placeholder="Inserisci e-mail"
                        />
                          <div v-if="submitted && this.$v.currentUser.email.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentUser.email.required">Campo obbligatorio</span>
                            <span v-if="!this.$v.currentUser.email.email">Indirizzo mail non valido</span>                      
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="mb-3">
                        <label class="control-label form-label">Telefono</label>
                        <input
                            id="phone"
                            v-model="currentUser.phone"
                            type="text"
                            class="form-control"
                            :disabled="edit === true"
                            :class="{ 'is-invalid': submitted && this.$v.currentUser.phone.$error }"
                            placeholder="Iserire telefono"
                        />
                          <div v-if="submitted && this.$v.currentUser.phone.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentUser.phone.required">Campo obbligatorio</span>
                            <span v-if="!this.$v.currentUser.phone.numeric">Valori non ammessi</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-12">
                      <div class="mb-3">
                        <label class="control-label form-label">Paese</label>
                        <input
                            id="city"
                            v-model="currentUser.city"
                            type="text"
                            class="form-control"
                            :disabled="edit === true"
                            :class="{ 'is-invalid': submitted && this.$v.currentUser.city.$error }"
                            placeholder="Inserire un paese"
                        />
                          <div v-if="submitted && this.$v.currentUser.city.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentUser.city.required">Campo obbligatorio</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-12">
                      <div class="mb-3">
                        <label class="control-label form-label">Provincia</label>
                        <input
                            id="province"
                            v-model="currentUser.province"
                            type="text"
                            class="form-control"
                            :disabled="edit === true"
                            :class="{ 'is-invalid': submitted && this.$v.currentUser.province.$error }"
                            placeholder="Inserire un paese"
                        />
                        <div v-if="submitted && this.$v.currentUser.province.$error"  class="col-12 invalid-feedback d-block">
                          <span v-if="!this.$v.currentUser.province.required">Campo obbligatorio</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-3">
                        <label class="control-label form-label">Data di Nascita</label>
                         <b-form-input
                            id="bDate"
                            v-model="currentUser.bDate"
                            type="date"
                            class="form-control"
                            :class="{ 'is-invalid': submitted && this.$v.currentUser.bDate.$error }"
                            :disabled="edit === true"
                            placeholder="Inserire data"
                            
                          ></b-form-input>
                          <div v-if="submitted && this.$v.currentUser.bDate.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currentUser.bDate.required">Campo obbligatorio</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-3">
                        <label class="control-label form-label">Sesso</label>
                        <div class="row">
                          <div class="col-12">
                            <b-form-radio
                              v-model="currentUser.sex"
                              :class="{ 'is-invalid': submitted && this.$v.currentUser.sex.$error }"
                              class="mb-3"
                              value="M"
                              :disabled="edit === true"
                              plain
                              >Uomo</b-form-radio
                            >
                          </div>
                          <div class="col-12">
                            <b-form-radio
                              v-model="currentUser.sex"
                              class="custom-radio mb-3"
                              :class="{ 'is-invalid': submitted && this.$v.currentUser.sex.$error }"
                              value="F"
                              :disabled="edit === true"
                              plain
                              >Donna</b-form-radio
                            >
                          </div>
                        </div>
                        <div v-if="submitted && this.$v.currentUser.sex.$error"  class="col-12 invalid-feedback d-block">
                          <span v-if="!this.$v.currentUser.sex.required">Campo obbligatorio</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-3 form-group">
                    <b-button variant="danger" v-if="!edit" @click="closeModal">Annulla</b-button>
                    <b-button v-if="!edit" type="submit" class="ms-1" variant="success" 
                      >Salva</b-button
                    >
                    <router-link v-if="edit" :to="'/store/patient/'+curId+'/main'" class="btn btn-primary ms-1"
                      >Visualizza dettagli </router-link>
                  </div>
                </form>
            </b-modal>
            <b-modal
              v-model="sellingModal"
              title="Edit Event"
              title-class="text-black font-18"
              hide-footer
              body-class="p-3"
            >
              <div slot="modal-title"><i class="uil uil-shopping-cart-alt font-size-18"></i> Vendita</div>
              <form @submit.prevent="handleSale()">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                     <label>Lenti acquistate: Qualità</label>
                      <select class="form-select" v-model="currentUser.selling">
                        <option value="N">Nessuna</option>
                        <option value="B">Base</option>
                        <option value="M">Media</option>
                        <option value="A">Avanzata</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="p-3 pl-0 form-group">
                  <b-button variant="danger" @click="closeModal()">Annulla</b-button>
                  <b-button type="submit" class="ms-1" variant="success" 
                    >Salva</b-button
                  >
                </div>
              </form>
            </b-modal>

            <!--Delete Modal start -->
            <b-modal
              v-model="deleteModal"
              title="Delete Event"
              title-class="text-black font-18"
              hide-footer
              body-class="p-3"
            >
              <div slot="modal-title"><i class="uil uil-user font-size-18"></i> Elimina Cliente</div>
              <form @submit.prevent="handleDelete">
                <div class="row">
                  <div class="col-12">
                    <p>Sei sicuro di voler eliminare l'utente?</p>
                  </div>  
                </div>
                <div class="p-3 form-group">
                  <b-button variant="danger" @click="closeModal">Annulla</b-button>
                  <b-button type="submit" class="ms-1" variant="success" 
                    >Conferma</b-button
                  >
                </div>
              </form>
            </b-modal>
            <!--Delete Modal end -->
          </div>
        </div>
      </div>
      <div class="col-12 text-center align-self-center" v-else-if="dataReady === false || inserting === true">
          <div class="lds-dual-ring"></div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import appConfig from "@/app.config";
import { required, email,numeric } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import API from "@/api.js";


/**
 * User list component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Elenco clienti",
    // meta: [
    //   {
    //     name: "description",
    //     content: appConfig.description,
    //   },
    // ],
  },
  data() {
    return {
      title: "Elenco Clienti",
      dataReady : false,
      inserting : false,
      items: [
        {
          text: this.$auth.user().role === 'StoreManager' ? this.$auth.user().store.company_name : this.$auth.user().doctor.store.company_name,
        },
        {
          text: "Elenco clienti",
          active: true,
        },
      ],
      store: this.$auth.user().role === 'StoreManager' ? this.$auth.user().store : this.$auth.user().doctor.store,
      userList: [],
      curId:undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        {
          key: "check",
          label: "",
        },
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "codCF",
          label: "C. Fiscale"
        },
        {
          key: "city",
          label: "Paese"
        },
        "Azioni",
      ],
       
      edit:true,
      eventModal: false,
      deleteModal: false,
      sellingModal: false,
      submitted:false,
      // color array
      colorIcon : ['#84acd7','#80d9ff','#fb503b','#fb913b','#8bbe1b'],

      sellingOptions: [
         "Base",
         "Medio",
         "Avanzato"
       ],
       
       currentUser: {
        name: "",
        lastname: "",
        codCF: "",
        bDate:"",
        sex:"",
        email:"",
        phone:"",
        city:"",
        province:"",
        selling:"",
      },
      // currentUser: {
      //   name: this.$faker().name.firstName(),
      //   lastname: this.$faker().name.lastName(),
      //   codCF: `TEST${this.$faker().finance.account()}`,
      //   bDate: (new Date()),
      //   sex: "M",
      //   email: this.$faker().internet.email(),
      //   phone: "3333333",
      //   city: this.$faker().address.cityName(),
      //   province: this.$faker().address.streetAbbr(),
      //   selling:"",
      // },
    };
  },
  validations: {
    currentUser: {
      name: { required },
      lastname: { required },
      codCF: { required },
      bDate: { required },
      sex: { required },
      email: { required, email },
      phone:{required, numeric},
      city:{required},
      province:{required},
      selling:{required}
    },

    validationInsert: [
      'currentUser.name',
      'currentUser.lastname',
      'currentUser.codCF',
      'currentUser.bDate',
      'currentUser.sex',
      'currentUser.email',
      'currentUser.phone',
      'currentUser.city',
      'currentUser.province'
    ],

    validationSelling: [
      'currentUser.selling'
    ],

  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.userList.length;
    },
  },
  mounted() {
    // Set the initial number of items
    // this.axios.get(API.getPatientsByStore.path(this.store.id)).then(response => {
    //   this.userList=response.data;
    //   // console.log(response)
    //   this.totalRows = this.userList.length;
    //   // console.log(this.totalRows);

    // }).catch(error=>{
    //   // console.log(error)

    // });
    this.reloadList();
  },
  methods: {

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

   
    insertUser() {
      this.currentUser = {};
      this.edit=false;
      this.eventModal = true;
      this.curId=undefined;
      this.currentUser.color=this.colorIcon[Math.floor(Math.random() * (5))];
      this.currentUser.store = this.store.id;
      this.currentUser.selling=false;
    },

    selectUser(item) {
      this.edit=true;
      this.$v.$reset();
      this.curId=item.id;
      this.currentUser.store = this.store.id;
      this.currentUser.name=item.name;
      this.currentUser.lastname=item.lastname;
      this.currentUser.codCF=item.codCF;
      this.currentUser.bDate=item.bDate;
      this.currentUser.sex=item.sex;
      this.currentUser.email=item.email;
      this.currentUser.phone=item.phone;
      this.currentUser.city=item.city;
      this.currentUser.province=item.province;

      this.eventModal = true;
    },

    selectSelling(item){
      this.currentUser.selling=item.selling;
      this.curId=item.id;
      this.sellingModal = true;
    },

     confirmDelete(id){
      this.deleteModal=true;
      this.curId=id;
    },

    randomUser() {
      const idx = Math.round(Math.random() * 1000);
      this.currentUser.codCF = `TESTCF${idx}`;
      this.currentUser.name = `Utente ${idx}`;
      this.currentUser.lastname = "Test";
      this.currentUser.bDate = "1990-01-01";
      this.currentUser.sex = "M";
      this.currentUser.email = `test${idx}@mailtest.it`;
      this.currentUser.phone = "3931245678";
      this.currentUser.city ="Roma";
      this.currentUser.province = "RO";
      this.handleSubmit();
      console.log(this.currentUser);
    },

   async handleSubmit(){
      
      this.submitted=true;
      this.inserting = true;
      this.$v.$touch();
      
      if (this.$v.validationInsert.$invalid) {
        return;

      } else {
        // await this.axios.post(API.postPatient.path(),this.currentUser).then(response =>{
        //     this.responsemsg("Nuovo cliente aggiunto", "success");
        //     this.closeModal();
        //     console.log(response)

        //   }).catch(error =>{
        //     this.responsemsg("Operazione fallita", "error")
        //     console.log(error)

        //   })
          const r = await this.axios.post(API.postPatient.path(),this.currentUser);
          if (r.status === 201) {
            this.responsemsg("Nuovo cliente aggiunto", "success");
            this.closeModal();
          } else {
            this.responsemsg("Operazione fallita", "error");
          }
      }
      this.inserting = false;
      this.reloadList();

    },

    async handleSale(){

      if (this.$v.validationSelling.$invalid) {
        return;

      } else {
      // await this.axios.put(API.addPatientSale.path(this.curId),this.currentUser).then(response =>{
      //   this.responsemsg("Vendita Registrata","success"); 
      //   this.closeModal();
      //   console.log(response)


      //   }).catch(error => {
      //       this.responsemsg("Operazione fallita", "error")
      //       console.log(error)

      //   });
      // }
        const r = await this.axios.put(API.addPatientSale.path(this.curId),this.currentUser);
        if (r.status === 200) {
          this.responsemsg("Vendita registrata", "success");
          this.closeModal();
        } else {
          this.responsemsg("Operazione fallita","error");
        }
      
        this.reloadList();
      }
    },

    async handleDelete(){
       
      
      // await this.axios.delete(API.deletePatient.path(this.curId)).then(response =>{
      //   this.responsemsg("Cliente eliminato","success"); 
      //   console.log(response)

      //   this.closeModal();  
      // }).catch(error => {
      //   this.responsemsg("Operazione fallita", "error")
      //   console.log(error)

      // });
      const r = await this.axios.delete(API.deletePatient.path(this.curId));
      console.log(r);
      if (r.status === 200 || r.status === 204) {
        this.responsemsg("Cliente eliminato","success");
      } else {
        this.responsemsg("Operazione fallita","error");
      }
      this.reloadList();


    },

    async reloadList(){
      

      this.totalRows = this.items.length;
      this.dataReady = false;
      this.axios.get(API.getPatientsByStore.path(this.store.id)).then(response => {
        this.userList=response.data;
        // console.log(response)
        this.dataReady = true;
      }).catch(error=>{
        console.log(error)
        this.dataReady = true;
      });

    },


    closeModal() {
      this.eventModal = false;
      this.currentUser = {};
      this.submitted=false;
      this.deleteModal=false;
      this.sellingModal = false;


      this.$v.$reset();
    },

    responsemsg(msg,type) {
      Swal.fire({
        position: "center",
        icon: type,
        title: msg,
        showConfirmButton: false,
        timer: 1000,
      });
    },



    
  },
  middleware: "authentication",
};
</script>
